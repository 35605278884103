import React from 'react';
import { Redirect } from '@reach/router';
import { graphql, Link } from 'gatsby';
import { Box, ResponsiveContext } from 'grommet';
import { format } from 'date-fns';
import {
  LibraryBlock,
  PublicationBody,
  PublicationContentBlock,
  PublicationHeader,
  PublicationSidebar,
  GatsbyButton,
} from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  query PublicationQuery($uid: String) {
    prismic {
      doc: allPublications(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            type
            date
            image
            caption
            text
            cta_text
            white_label_gate
            cta_link {
              ... on PRISMIC__FileLink {
                url
              }
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__ImageLink {
                url
              }
              ... on PRISMIC_Publication {
                title
                _meta {
                  uid
                }
              }
            }
            hubspot_subscription_form_id
            meta_title
            meta_description
            author {
              ... on PRISMIC_Author {
                name
                job_title
                photo
                bio
                contact_link {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
            author_byline
            body {
              ... on PRISMIC_PublicationBodyEvent_data {
                primary {
                  event_start_date
                  event_end_date
                  event_location
                  event_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_PublicationBodyProject_proof_data {
                primary {
                  project_proof_client
                  project_proof_location
                  stat_1_value
                  stat_1_context
                  stat_1_source
                  stat_2_value
                  stat_2_context
                  stat_2_source
                  stat_3_value
                  stat_3_context
                  stat_3_source
                }
              }
              ... on PRISMIC_PublicationBodyCurrents_article_data {
                primary {
                  newsletter {
                    ... on PRISMIC_Newsletter {
                      _meta {
                        uid
                      }
                      title
                      signup_text
                    }
                  }
                }
              }
              ... on PRISMIC_PublicationBodyWhite_paper_data {
                primary {
                  pdf {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                }
              }
            }
            related_markets {
              market {
                ... on PRISMIC_Market {
                  name
                }
              }
            }
            related_regions {
              region
            }
            related_service_areas {
              service_area {
                ... on PRISMIC_Service_area {
                  name
                }
              }
            }
            external_links {
              link_text
              link_url {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            related_publications {
              publication {
                ... on PRISMIC_Publication {
                  _meta {
                    id
                    uid
                  }
                  title
                  type
                  image
                  summary
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function({ data, location }) {
  // Check route
  const doc = data.prismic.doc.edges[0];

  if (!doc) return <Redirect to="/library" noThrow />;

  // Set content
  const content = doc.node;
  const headerProps = {
    type: content.type,
    title: content.title,
    date: content.date,
    image: content.image,
    caption: content.caption,
    author: content.author && content.author.name,
    authorTitle: content.author && content.author.job_title,
    authorByline: content.author_byline,
    client:
      ['Project', 'Project Proof'].includes(content.type) &&
      content.body &&
      content.body[0].primary.project_proof_client,
    location:
      ['Project', 'Project Proof'].includes(content.type) &&
      content.body &&
      content.body[0].primary.project_proof_location,
  };
  const contentBlockProps = {
    type: content.type,
    authorProps: content.author && {
      name: content.author.name,
      position: content.author.job_title,
      blurb: content.author.bio,
      image: content.author.photo,
      contactText: `Contact ${content.author.name.trim().split(' ')[0]}`,
      contactUrl: content.author.contact_link.url,
    },
  };
  const downloadBlockProps =
    {
      downloadProps: content.type === 'White Paper' &&
        content.body && {
          label: 'Download',
          href:
            content.body &&
            content.body[0] &&
            content.body[0].primary &&
            content.body[0].primary.pdf &&
            content.body[0].primary.pdf.url,
        },
    } || '';
  // Set sidebar
  const sidebar = [];
  if (content.body && content.body.length && content.body[0].primary) {
    if (content.body[0].primary.newsletter) {
      sidebar.push({
        heading: 'Newsletter',
        links: [
          {
            type: 'link',
            text: content.body[0].primary.newsletter.title[0].text,
            href: `/newsletters/${content.body[0].primary.newsletter._meta.uid}`,
          },
        ],
      });
    }
    if (content.body[0].primary.event_start_date) {
      const startDate = format(
        new Date(`${content.body[0].primary.event_start_date}T00:00:00`),
        'MMMM do, yyyy',
      );

      const endDate = content.body[0].primary.event_end_date
        ? format(new Date(`${content.body[0].primary.event_end_date}T00:00:00`), 'MMMM do, yyyy')
        : null;

      const dateText = [
        {
          type: 'text',
          text: startDate,
        },
      ];
      if (endDate) {
        dateText.push({
          type: 'text',
          text: 'to',
        });
        dateText.push({
          type: 'text',
          text: endDate,
        });
      }
      sidebar.push({
        heading: 'Date',
        links: dateText,
      });
      sidebar.push({
        heading: 'Location',
        links: [
          {
            type: 'text',
            text: content.body[0].primary.event_location,
          },
        ],
      });
      if (content.body[0].primary.event_link) {
        sidebar.push({
          heading: 'More Information',
          links: [
            {
              type: 'external link',
              text: 'Event Page',
              href: content.body[0].primary.event_link.url,
            },
          ],
        });
      }
    }
  }
  if (content.related_markets && content.related_markets[0].market) {
    const links = content.related_markets.map((item) => {
      return (
        item.market && {
          type: 'link',
          text: item.market.name,
          href: `/library?market=${encodeURIComponent(item.market.name)}&type=${encodeURIComponent(
            content.type,
          )}`,
        }
      );
    });
    sidebar.push({
      heading: 'Industries',
      links,
    });
  }
  if (content.related_regions && content.related_regions[0].region) {
    const links = content.related_regions.map((item) => {
      return (
        item.region && {
          type: 'link',
          text: item.region,
          href: `/library?region=${encodeURIComponent(item.region)}&type=${encodeURIComponent(
            content.type,
          )}`,
        }
      );
    });
    sidebar.push({
      heading: 'Regions',
      links,
    });
  }
  if (content.related_service_areas && content.related_service_areas[0].service_area) {
    const links = content.related_service_areas.map((item) => {
      return (
        item.service_area && {
          type: 'link',
          text: item.service_area.name[0].text,
          href: `/library?service=${encodeURIComponent(
            item.service_area.name[0].text,
          )}&type=${encodeURIComponent(content.type)}`,
        }
      );
    });
    sidebar.push({
      heading: 'Services',
      links,
    });
  }

  // External links
  let externalLinks = null;
  if (
    content.external_links &&
    content.external_links.length &&
    content.external_links[0].link_text
  ) {
    externalLinks = [
      {
        heading: 'External Links',
        links: [],
      },
    ];
    externalLinks[0].links = content.external_links.map((item) => {
      return (
        item.link_text &&
        item.link_url && {
          type: 'external link',
          text: item.link_text,
          href: item.link_url.url,
        }
      );
    });
  }

  // Related content
  let relatedContent = null;
  if (
    content.related_publications &&
    content.related_publications.length &&
    content.related_publications[0].publication
  ) {
    relatedContent = content.related_publications.slice(0, 3).map((item) => {
      const excerpt = item.publication.summary
        ? item.publication.summary[0].text
        : item.publication.text && item.publication.text[0].text;

      return {
        id: item.publication._meta.id,
        category: item.publication.type,
        title: item.publication.title,
        to: `/library/${item.publication._meta.uid}`,
        image: item.publication.image.thumbnail,
        excerpt: excerpt && excerpt.length > 140 ? `${excerpt.substring(0, 140)}…` : excerpt,
        linkText: 'Read more',
      };
    });
  }

  function mainPadding(size) {
    switch (size) {
      case 'small':
        return { horizontal: 'none', vertical: 'large' };
      case 'medium':
        return { horizontal: 'large', vertical: 'large' };
      default:
        return { horizontal: 'xlarge', vertical: '78px' };
    }
  }

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.image && content.image.url}
    >
      <ResponsiveContext.Consumer>
        {(size) => {
          const isSmall = size === 'small';
          const isMedium = size === 'medium';
          return (
            <Box pad={mainPadding(size)}>
              <Box
                alignContent="center"
                direction="row-responsive"
                gap={isMedium ? 'large' : 'xlarge'}
                margin="auto"
                width="1440px"
              >
                <Box basis={isMedium ? '2/3' : '3/4'}>
                  <PublicationHeader Link={Link} htmlSerializer={htmlSerializer} {...headerProps} />
                  <PublicationBody
                    Link={Link}
                    bodyText={content.text}
                    cta={{
                      text: content.cta_text || 'Learn More',
                      url:
                        content.cta_link &&
                        (content.cta_link.url || `/library/${content.cta_link._meta.uid}`),
                    }}
                    htmlSerializer={htmlSerializer}
                    hubspotFormId={content.hubspot_subscription_form_id}
                    whitePaperDownload={downloadBlockProps?.downloadProps}
                    whitePaperLabel={downloadBlockProps?.downloadProps?.label}
                    whitePaperGate={content.white_label_gate}
                    location={location}
                  />
                  <PublicationContentBlock
                    contentBlock={contentBlockProps}
                    htmlSerializer={htmlSerializer}
                  />
                </Box>
                <Box basis={isMedium ? '1/3' : '1/4'} pad={isSmall && { horizontal: 'medium' }}>
                  <GatsbyButton
                    Link={Link}
                    href="/library/"
                    label="Library"
                    margin={{ bottom: isSmall ? 'medium' : 'large' }}
                    style={{ textAlign: 'center' }}
                  />
                  <PublicationSidebar Link={Link} sidebar={sidebar} externalLinks={externalLinks} />
                </Box>
              </Box>
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
      {relatedContent && (
        <Box background="light-3">
          <LibraryBlock
            Link={Link}
            headingLevel="3"
            libraryCards={relatedContent}
            titleText="Related Content"
          />
        </Box>
      )}
    </Layout>
  );
}
